body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FA;
  min-height: 100vh;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Better focus outline */
:focus {
  outline: 2px solid #2C3E50;
  outline-offset: 2px;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #2C3E50;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #34495E;
}

code {
  font-family: 'Source Code Pro', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Add this to your existing CSS */
.MuiToolbar-root {
  min-height: 70px !important;
}

/* Add padding to account for fixed navbar */
/* main { */
  /* padding-top: 70px; */
/* } */

/* Add these styles */
/* .content-wrapper { */
  /* padding-top: 70px; Same as NAVBAR_HEIGHT */
/* } */

.hero-wrapper {
  margin-top: -70px; /* Negative NAVBAR_HEIGHT */
}
